import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import PartnerCard from '../../components/card/Partner';
import PaginationBlock from '../../components/block/Pagination';

// Partners
const PartnersPage = ({ pageContext, data }) => {
  const { totalCount } = data.partners
  const pageTitle = `Partenaires - ${totalCount} partenaire${totalCount === 1 ? "" : "s"}`
  
  const headerBackgroundImageRandom = Math.floor(Math.random() * data.partners.nodes.length);
  const headerBackgroundImage = data.partners.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.partners.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - Un cercle de qualité`}
      pageDescription={`Découvrez les partenaires de ${data.site.siteMetadata.title}. De nombreuses entreprises nous font confiance depuis 70 ans.`}
      pageKeywords="moussé gava, partenaires, partenaire, entreprises, clients, le coteau, riorges, décoration, plâtrerie, peinture, façade"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Nos partenaires</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Partenaires</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.partners && data.partners.nodes.length ?
        <div className="wpo-partner-area section-padding">
          <div className="container">
            <div className="row align-items-center">
              {data.partners.nodes.map(partner => (
                <PartnerCard key={partner.id} data={partner} cardStyle="2" cardClassName="col-lg-4 col-md-6 col-12" />
              ))}
            </div>
            <div>
              <PaginationBlock
                path={`partners`}
                currentPage={pageContext.currentPage}
                numPages={pageContext.numPages}
              />
            </div>
          </div>
        </div>
      : null}
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    partners: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/partners/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          name
          description
          image {
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default PartnersPage